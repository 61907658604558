
import Vue from 'vue'
import { mapFields } from 'vuex-map-fields'
import { UXState } from '@/bookingbuddy-admin/store/ux'
import Spinner from '@/shared/components/partials/Spinner.vue'

const uxStoreFields = {
  colorTheme: 'colorTheme',
}

export default Vue.extend({
  name: 'ConfirmLogin',
  components: {
    Spinner,
  },
  layout: 'unauthenticated',
  data() {
    return {
      registerUrl: this.$config.cognitorBaseUri + '/register',
      errorText: '',
    }
  },
  mounted() {
    this.$teams.app.notifyAppLoaded()
    this.$teams.app.notifySuccess()
  },
  computed: {
    ...mapFields<typeof uxStoreFields, UXState>('ux', uxStoreFields),
    teamsInitialized(): boolean {
      return !!this.$teams?.app.isInitialized()
    },
  },
  methods: {
    async login() {
      try {
        const context = await this.$teams.app.getContext()

        const successMessage = await this.$teams.authentication.authenticate({
          url:
            this.$config.baseUrl +
            '/login?email=' +
            context.user?.userPrincipalName,
          width: 600,
          height: 800,
          isExternal: false,
        })

        // redirect to index
        await this.handleSuccessfulAuthentication(successMessage)
      } catch (e) {
        console.log(e)
      }
    },
    async handleSuccessfulAuthentication(msg: string) {
      try {
        const message = JSON.parse(msg)

        if (message.message === 'anny-login-done') {
          // Save access token and refresh token
          this.$authService.updateTokens({
            accessToken: message.access_token,
            refreshToken: message.refresh_token,
            expiresIn: message.expires_in,
            saveAccessToken: true,
          })
          await this.$authService.requestCustomerAccount()

          // set path and do a full reload, because else
          // we don't have reactivity in the main page
          // this is for now a quick and dirty solution.
          // For the person in the future who wants to fix it:
          // Good luck my friend, and thank you for your sacrifice
          window.location.href =
            this.$config.baseUrl +
            this.localeRoute({
              path: '/',
            })!.path

          // await this.$router.push(
          //   this.localePath({
          //     path: '/',
          //   })
          // )
        }
      } catch (e) {
        console.log(e)
        return
      }
    },
  },
})
